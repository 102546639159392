/*
 * @Author: kai 17660422924@163.com
 * @Date: 2024-03-28 17:23:02
 * @LastEditors: kai 17660422924@163.com
 * @LastEditTime: 2024-03-29 14:21:17
 * @FilePath: \mmc-vue-tianmu\src\components\mount\MMC_Gimbal_P30\js\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//CRC 校验表
var crc_table = new Array(
  0x00, 0x31, 0x62, 0x53, 0xc4, 0xf5, 0xa6, 0x97, 0xb9, 0x88, 0xdb, 0xea, 0x7d, 0x4c, 0x1f, 0x2e,
  0x43, 0x72, 0x21, 0x10, 0x87, 0xb6, 0xe5, 0xd4, 0xfa, 0xcb, 0x98, 0xa9, 0x3e, 0x0f, 0x5c, 0x6d,
  0x86, 0xb7, 0xe4, 0xd5, 0x42, 0x73, 0x20, 0x11, 0x3f, 0x0e, 0x5d, 0x6c, 0xfb, 0xca, 0x99, 0xa8,
  0xc5, 0xf4, 0xa7, 0x96, 0x01, 0x30, 0x63, 0x52, 0x7c, 0x4d, 0x1e, 0x2f, 0xb8, 0x89, 0xda, 0xeb,
  0x3d, 0x0c, 0x5f, 0x6e, 0xf9, 0xc8, 0x9b, 0xaa, 0x84, 0xb5, 0xe6, 0xd7, 0x40, 0x71, 0x22, 0x13,
  0x7e, 0x4f, 0x1c, 0x2d, 0xba, 0x8b, 0xd8, 0xe9, 0xc7, 0xf6, 0xa5, 0x94, 0x03, 0x32, 0x61, 0x50,
  0xbb, 0x8a, 0xd9, 0xe8, 0x7f, 0x4e, 0x1d, 0x2c, 0x02, 0x33, 0x60, 0x51, 0xc6, 0xf7, 0xa4, 0x95,
  0xf8, 0xc9, 0x9a, 0xab, 0x3c, 0x0d, 0x5e, 0x6f, 0x41, 0x70, 0x23, 0x12, 0x85, 0xb4, 0xe7, 0xd6,
  0x7a, 0x4b, 0x18, 0x29, 0xbe, 0x8f, 0xdc, 0xed, 0xc3, 0xf2, 0xa1, 0x90, 0x07, 0x36, 0x65, 0x54,
  0x39, 0x08, 0x5b, 0x6a, 0xfd, 0xcc, 0x9f, 0xae, 0x80, 0xb1, 0xe2, 0xd3, 0x44, 0x75, 0x26, 0x17,
  0xfc, 0xcd, 0x9e, 0xaf, 0x38, 0x09, 0x5a, 0x6b, 0x45, 0x74, 0x27, 0x16, 0x81, 0xb0, 0xe3, 0xd2,
  0xbf, 0x8e, 0xdd, 0xec, 0x7b, 0x4a, 0x19, 0x28, 0x06, 0x37, 0x64, 0x55, 0xc2, 0xf3, 0xa0, 0x91,
  0x47, 0x76, 0x25, 0x14, 0x83, 0xb2, 0xe1, 0xd0, 0xfe, 0xcf, 0x9c, 0xad, 0x3a, 0x0b, 0x58, 0x69,
  0x04, 0x35, 0x66, 0x57, 0xc0, 0xf1, 0xa2, 0x93, 0xbd, 0x8c, 0xdf, 0xee, 0x79, 0x48, 0x1b, 0x2a,
  0xc1, 0xf0, 0xa3, 0x92, 0x05, 0x34, 0x67, 0x56, 0x78, 0x49, 0x1a, 0x2b, 0xbc, 0x8d, 0xde, 0xef,
  0x82, 0xb3, 0xe0, 0xd1, 0x46, 0x77, 0x24, 0x15, 0x3b, 0x0a, 0x59, 0x68, 0xff, 0xce, 0x9d, 0xac
);

var control_speed = 20;
var cam_index = 0;
var altitude = 0;
var latitude = 0;
var longitude = 0;
var record_status = 0;
var click_mode_status = 1;
var goteuler2msg = false;
var gotstatus2 = false;
var gotversion = false;
var pitch_move = false;
var yaw_move = false;
var gimbal_mode = 1;
var IAMODE = 0;

var backlight = false;
var defog = false;
var nightmode = false;
var finger_mode = 0;
var photo_last = 0;
var record_last = 0;
var zoom_last = 0;
var gimbal_mode_last = 0;
var camera_mode_last = 0;
var f1_last = 0;
var f2_last = 0;
var f3_last = 0;
var f4_last = 0;
var joint_control_status = 0;

var pitch_last = 0;
var yaw_last = 0;
////////////////////////////////viewpro viewlink protocol for MMC ////////////////////////////////////////////////////////
// viewlink protocol for MMC drone  2020-12
var viewlink_cmdID = {
  A1C1E1: 0X30,
  A1C1E1S1: 0X32,
  A2C2E2: 0X31,
  A2C2E2S2: 0X33,
  A1: 0X1A,
  A2: 0X2A,
  C1: 0X1C,
  C2: 0X2C,
  E1: 0x1E,
  E2: 0X2E,
  S1: 0X16,
  S2: 0X26,
  U: 0X01,
  V: 0X02,
  M_AHRS: 0XB1,
  HEART_BEAT: 0X10,
  SHAKE_HAND: 0X00,
  T1F1B1D1: 0x40,
  T2F2B2D2: 0X41,
  FW: 0XFF
}



var viewlink_cmdLEN = {
  // cmd_DATA_len + 3(len+cmdid+cs)
  A1C1E1: 17,
  A1C1E1S1: 31,
  A2C2E2: 13,
  A2C2E2S2: 18,
  T1F1B1D1: 44,
  T2F2B2D2: 52,
  A1: 12,
  A2: 5,
  C1: 5,
  C2: 6,
  E1: 6,
  E2: 8,
  S1: 17,
  S2: 8,
  U: 5,
  V: 5,
  M_AHRS: 45,
  HEART_BEAT: 4,
  SHAKE_HAND: 4,
  ////FOLLOW CMD NOT +3
  T1F1B1D1_DATA: 41,
  T2F2B2D2_DATA: 49,
  T1_DATA: 22,
  F1_DATA: 1,
  B1_DATA: 6,
  D1_DATA: 12,
  T2_DATA: 18,
  F2_DATA: 15,
  B2_DATA: 11,
  D2_DATA: 5
}


var viewlinkheadlen = 3;
var viewlinkFrameLen = {// the position of checksum
  A1C1E1: viewlink_cmdLEN.A1C1E1 + viewlinkheadlen,
  A1C1E1S1: viewlink_cmdLEN.A1C1E1S1 + viewlinkheadlen,
  A2C2E2: viewlink_cmdLEN.A2C2E2 + viewlinkheadlen,
  A2C2E2S2: viewlink_cmdLEN.A2C2E2S2 + viewlinkheadlen,
  A1: viewlink_cmdLEN.A1 + viewlinkheadlen,
  A2: viewlink_cmdLEN.A2 + viewlinkheadlen,
  C1: viewlink_cmdLEN.C1 + viewlinkheadlen,
  C2: viewlink_cmdLEN.C2 + viewlinkheadlen,
  E1: viewlink_cmdLEN.E1 + viewlinkheadlen,
  E2: viewlink_cmdLEN.E2 + viewlinkheadlen,
  S1: viewlink_cmdLEN.S1 + viewlinkheadlen,
  S2: viewlink_cmdLEN.S2 + viewlinkheadlen,
  U: viewlink_cmdLEN.U + viewlinkheadlen,
  V: viewlink_cmdLEN.V + viewlinkheadlen,
  M_AHRS: viewlink_cmdLEN.M_AHRS + viewlinkheadlen,
  HEART_BEAT: viewlink_cmdLEN.HEART_BEAT + viewlinkheadlen,
  SHAKE_HAND: viewlink_cmdLEN.SHAKE_HAND + viewlinkheadlen,

}


var viewlink_cs_pos = {// the position of checksum
  A1C1E1: viewlinkFrameLen.A1C1E1 - 1,
  A1C1E1S1: viewlinkFrameLen.A1C1E1S1 - 1,
  A2C2E2: viewlinkFrameLen.A2C2E2 - 1,
  A2C2E2S2: viewlinkFrameLen.A2C2E2S2 - 1,
  A1: viewlinkFrameLen.A1 - 1,
  A2: viewlinkFrameLen.A2 - 1,
  C1: viewlinkFrameLen.C1 - 1,
  C2: viewlinkFrameLen.C2 - 1,
  E1: viewlinkFrameLen.E1 - 1,
  E2: viewlinkFrameLen.E2 - 1,
  S1: viewlinkFrameLen.S1 - 1,
  S2: viewlinkFrameLen.S2 - 1,
  U: viewlinkFrameLen.U - 1,
  V: viewlinkFrameLen.V - 1,
  M_AHRS: viewlinkFrameLen.M_AHRS - 1,
  HEART_BEAT: viewlinkFrameLen.HEART_BEAT - 1,
  SHAKE_HAND: viewlinkFrameLen.SHAKE_HAND - 1,

}


var mmcAddLen = 2;//(len+cs)
var mmcCmdLen = {// the position of checksum
  A1C1E1: viewlinkFrameLen.A1C1E1 + mmcAddLen,
  A1C1E1S1: viewlinkFrameLen.A1C1E1S1 + mmcAddLen,
  A2C2E2: viewlinkFrameLen.A2C2E2 + mmcAddLen,
  A2C2E2S2: viewlinkFrameLen.A2C2E2S2 + mmcAddLen,
  A1: viewlinkFrameLen.A1 + mmcAddLen,
  A2: viewlinkFrameLen.A2 + mmcAddLen,
  C1: viewlinkFrameLen.C1 + mmcAddLen,
  C2: viewlinkFrameLen.C2 + mmcAddLen,
  E1: viewlinkFrameLen.E1 + mmcAddLen,
  E2: viewlinkFrameLen.E2 + mmcAddLen,
  S1: viewlinkFrameLen.S1 + mmcAddLen,
  S2: viewlinkFrameLen.S2 + mmcAddLen,
  U: viewlinkFrameLen.U + mmcAddLen,
  V: viewlinkFrameLen.V + mmcAddLen,
  M_AHRS: viewlinkFrameLen.M_AHRS + mmcAddLen,
  HEART_BEAT: viewlinkFrameLen.HEART_BEAT + mmcAddLen,
  SHAKE_HAND: viewlinkFrameLen.SHAKE_HAND + mmcAddLen,
}



var mmcHeadLen = 2;
var mmcFrameLen = {// the position of checksum
  A1C1E1: mmcCmdLen.A1C1E1 + mmcHeadLen,
  A1C1E1S1: mmcCmdLen.A1C1E1S1 + mmcHeadLen,
  A2C2E2: mmcCmdLen.A2C2E2 + mmcHeadLen,
  A2C2E2S2: mmcCmdLen.A2C2E2S2 + mmcHeadLen,
  A1: mmcCmdLen.A1 + mmcHeadLen,
  A2: mmcCmdLen.A2 + mmcHeadLen,
  C1: mmcCmdLen.C1 + mmcHeadLen,
  C2: mmcCmdLen.C2 + mmcHeadLen,
  E1: mmcCmdLen.E1 + mmcHeadLen,
  E2: mmcCmdLen.E2 + mmcHeadLen,
  S1: mmcCmdLen.S1 + mmcHeadLen,
  S2: mmcCmdLen.S2 + mmcHeadLen,
  U: mmcCmdLen.U + mmcHeadLen,
  V: mmcCmdLen.V + mmcHeadLen,
  M_AHRS: mmcCmdLen.M_AHRS + mmcHeadLen,
  HEART_BEAT: mmcCmdLen.HEART_BEAT + mmcHeadLen,
  SHAKE_HAND: mmcCmdLen.SHAKE_HAND + mmcHeadLen,
}

var mmc_cs_pos = {// the position of checksum
  A1C1E1: mmcFrameLen.A1C1E1 - 1,
  A1C1E1S1: mmcFrameLen.A1C1E1S1 - 1,
  A2C2E2: mmcFrameLen.A2C2E2 - 1,
  A2C2E2S2: mmcFrameLen.A2C2E2S2 - 1,
  A1: mmcFrameLen.A1 - 1,
  A2: mmcFrameLen.A2 - 1,
  C1: mmcFrameLen.C1 - 1,
  C2: mmcFrameLen.C2 - 1,
  E1: mmcFrameLen.E1 - 1,
  E2: mmcFrameLen.E2 - 1,
  S1: mmcFrameLen.S1 - 1,
  S2: mmcFrameLen.S2 - 1,
  U: mmcFrameLen.U - 1,
  V: mmcFrameLen.V - 1,
  M_AHRS: mmcFrameLen.M_AHRS - 1,
  HEART_BEAT: mmcFrameLen.HEART_BEAT - 1,
  SHAKE_HAND: mmcFrameLen.SHAKE_HAND - 1,
}

var FW_data_len = 0;
var viewlink_cmdLEN_FW = FW_data_len + 3;
var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
var viewlink_cs_pos_FW = viewlinkFrameLen_FW - 1;
var mmcCmdLen_FW = viewlinkFrameLen_FW + mmcAddLen;
var mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
var mmc_cs_pos_FW = mmcFrameLen_FW - 1;

var viewlink_cmd_ID_LEN = {
  cmdID: viewlink_cmdID.A1,
  cmdLEN: viewlink_cmdLEN.A1
}
// A1 
var A1_SERVO_STATUS = {
  motoroff: 0x00,
  manualcontrol: 0x01,
  followyaw: 0x03,
  homeposition: 0x04,
  trackmode: 0x06,
  turntorelativeangle: 0x09,
  lockyaw: 0x0a,
  turntoframeangle: 0x0b,
  rcmode: 0x0d,
  movetofinger: 0x0E,
  noaction: 0x0f,
  lookdown: 0x12,
  centeryaw: 0x16,
  levelpitch: 0x17
}
var A1_PARAM_INT16 = {
  PARAM1: 0x0000,
  PARAM2: 0x0000,
  PARAM3: 0x0000,
  PARAM4: 0x0000
}
var A1_CMD = {
  server_status: A1_SERVO_STATUS.noaction,
  param1: A1_PARAM_INT16.PARAM1,
  param2: A1_PARAM_INT16.PARAM2,
  param3: A1_PARAM_INT16.PARAM3,
  param4: A1_PARAM_INT16.PARAM4
}
var A1_CMD_RC = {
  server_status: A1_SERVO_STATUS.noaction,
  param1: A1_PARAM_INT16.PARAM1,
  param2: A1_PARAM_INT16.PARAM2,
  param3: A1_PARAM_INT16.PARAM3,
  param4: A1_PARAM_INT16.PARAM4
}
var A1_CMD_MOVE = {
  server_status: A1_SERVO_STATUS.noaction,
  param1: A1_PARAM_INT16.PARAM1,
  param2: A1_PARAM_INT16.PARAM2,
  param3: A1_PARAM_INT16.PARAM3,
  param4: A1_PARAM_INT16.PARAM4
}

var A1_CMD_ANGLE = {
  server_status: A1_SERVO_STATUS.noaction,
  param1: A1_PARAM_INT16.PARAM1,
  param2: A1_PARAM_INT16.PARAM2,
  param3: A1_PARAM_INT16.PARAM3,
  param4: A1_PARAM_INT16.PARAM4
}

function A1_to_array(A1_CMD) {
  var array = new Array();
  array[0] = A1_CMD.server_status;

  array[1] = A1_CMD.param1 >> 8;
  array[2] = A1_CMD.param1 & 0xff;

  array[3] = A1_CMD.param2 >> 8;
  array[4] = A1_CMD.param2 & 0xff;

  array[5] = A1_CMD.param3 >> 8;
  array[6] = A1_CMD.param3 & 0xff;

  array[7] = A1_CMD.param4 >> 8;
  array[8] = A1_CMD.param4 & 0xff;

  return array;
}
////////////////AI END//////////////////////
var viewlink_A2_servo = {
  noAction: 0x00,
  yawZeroAdj: 0x08
}
var viewlink_A2_fdctr = {
  disable: 0,
  enable: 1
}
var viewlink_A2_framecount = {
  counter: 0
}
var viewlink_A2_param = {
  param1: 0x00
}

var A2_CMD = {
  servo: viewlink_A2_servo.noAction,
  fdctr: viewlink_A2_fdctr.enable,
  framecounter: viewlink_A2_framecount.counter,
  param1: viewlink_A2_param.param1
}
function A2_to_array(A2_CMD) {
  var array = new Array();
  var temp = 0x0;
  temp = (A2_CMD.servo & 0x1f) | ((A2_CMD.fdctr & 0x01) << 5) | ((A2_CMD.framecounter & 0x03) << 6);
  array[0] = temp & 0xff;
  array[1] = A2_CMD.param1;
  return array;
}
var C1_VIDEO_SOURCE = {
  noaction: 0,
  EO: 1,
  IR: 2,
  EOir: 3,
  IReo: 4,
  fusion: 5
}

var C1_ZOOM_SPEED = {
  noaction: 0,
  zoomspeed1: 1,
  zoomspeed2: 2,
  zoomspeed3: 3,
  zoomspeed4: 4,
  zoomspeed5: 5,
  zoomspeed6: 6,
  zoomspeed7: 7
}

var C1_CAM_CTR = {
  noaction: 0x0000,
  stopfocus: 0x0001,
  brightup: 0x0002,
  brightdown: 3,
  zoomout: 8,
  zoomin: 9,
  focusout: 0x0a,
  focusin: 0x0b,
  whitehot: 0x0e,
  blackhot: 0x0f,
  colorhot: 0x12,
  takepicture: 0x13,
  startrecord: 0x14,
  stoprecord: 0x15,
  topicturemode: 0x16,
  torecordmode: 0x17,
  invertmode: 0x18,
  afon: 0x19,
  afoff: 0x1a,
  irdzoomin: 0x1b,
  irdzoomout: 0x1c,
  sdcardformat: 0x1d
}
var C1_LRF = {
  noaction: 0,
  onetimeLRF: 1,
  LRFstart: 2,
  LRFstart2: 3,
  outsynLRF: 4,
  stopLRF: 5
}
var C1_CMD = {
  videosource: C1_VIDEO_SOURCE.noaction,
  zoom_speed: C1_ZOOM_SPEED.speed,
  camera_ctrl: C1_CAM_CTR.noaction,
  LRFctr: C1_LRF.noaction
}
function C1_to_array(C1_CMD) {
  var array = new Array();
  var temp = 0x0000;
  temp = (C1_CMD.videosource & 0x07) | ((C1_CMD.zoom_speed & 0x07) << 3)
    | ((C1_CMD.camera_ctrl & 0x007f) << 6) | ((C1_CMD.LRFctr & 0x07) << 13);
  array[0] = (temp >> 8) & 0xff;
  array[1] = temp & 0xff;
  return array;
}
//////////////////C1 END/////////////////
//////////////////////C2 ////////////////////
var C2_ctrl = {
  noaction: 0,
  eodzoomon: 6,
  eodzoomoff: 7,
  eoVEon: 0x10,
  eoVEoff: 0x11,
  ircolorbaron: 0x12,
  ircolorbaroff: 0x13,
  eoflipoff: 0x14,
  eoflipon: 0x15,
  defogon: 0x16,
  defogoff: 0x17,
  osdon: 0x18,
  osdoff: 0x19,
  irflipoff: 0x1a,
  irflipon: 0x1b,
  eoICRon: 0x4a,
  eoICRoff: 0x4b,
  eozoomto: 0x53,
  laserctrl: 0x74
}
var C2_laser_cmd = {
  noaction: 0,
  laseron: 0X0100,
  laseroff: 0X0200,
  laserzoomout: 0X0400,
  laserzoomin: 0X0500,
  synauto: 0X0600,
  manulaser: 0X0700

}
var C2_CMD = {
  ctrl: C2_ctrl.noaction,
  param: C2_laser_cmd.noaction
}
var C2_CMD_ZOOM = {
  ctrl: C2_ctrl.eozoomto,
  param: 0x0000
};
function C2_to_array(C2_CMD) {
  var array = new Array();
  array[0] = C2_CMD.ctrl;
  array[1] = C2_CMD.param >> 8;
  array[2] = C2_CMD.param & 0XFF;
  return array;
}

/////////////////////////////////C2 END//////////


//////////////E1  ///////////////////
var E1_tracksource = {
  noaction: 0,
  eo1: 1,
  ir: 2,
  eo2: 3
}
var E1_param1 = {
  param1: 0x00
}
var E1_ctrl = {
  noaction: 0,
  stoptrack: 1,
  searchobject: 2,
  starttrack: 3,
  re_track: 4,
  ai_detec: 5,
  ai_opendetec: 0x01,
  ai_closedetec: 0x00,
  smalltemplate: 0x21,
  middletemplate: 0x22,
  bigtemplate: 0x23,
  midsmalltemplate: 0x24,
  bigmidtemplate: 0x26,
  autotemplate: 0x28
}

var E1_param2 = {
  param2: 0x00
}
var E1_CMD = {
  tracksource: E1_tracksource.noaction,
  param1: A1_PARAM_INT16.PARAM1,
  ctrl: E1_ctrl.noaction,
  param2: E1_param2.param2
}

function E1_to_array(E1_CMD) {
  var array = new Array();
  var temp = 0x00;
  temp = (E1_CMD.tracksource) | (E1_CMD.param1 << 3);
  array[0] = temp;
  array[1] = E1_CMD.ctrl;
  array[2] = E1_CMD.param2;
  return array;
}

/////////E1 END /////////////////
var E2_ctr = {
  noaction: 0,
  fingertracktopos: 0x0a
}
var E2_CMD = {
  ctr: E2_ctr.noaction,
  param1: 0x0000,
  param2: 0x0000

}
function E2_to_array(E2_CMD) {
  var array = new Array();
  array[0] = E2_CMD.ctr;
  array[1] = E2_CMD.param1 >> 8;
  array[2] = E2_CMD.param1 & 0xff;
  array[3] = E2_CMD.param2 >> 8;
  array[4] = E2_CMD.param2 & 0xff;
  return array;
}


//  var FW_CMD_data = new Array();

/* function FW_to_array(){
   var array = new Array();
   var i=0;
   for(i=0;i< FW_data_len;i++){
       array[i] = FW_CMD_data[i];
   }
   return array;
} */
//viewlink checksum data
function viewlink_checksum(array) {
  var checksum = array[3];
  var i = 4;
  var len = (array[3] & 0x3f) - 2;
  while (len--) {
    checksum = checksum ^ array[i];
    i++;
  }
  return checksum;
}
function pack_A1(A1CMD) {
  var A1_data_array = A1_to_array(A1CMD);//9
  var A1pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
  A1pack_array[3] = viewlink_cmdLEN.A1; //12
  A1pack_array[4] = viewlink_cmdID.A1;

  A1_data_array.forEach(function (item) { // 将A1CMD的数据提取出，拼接在A1pack_array
    A1pack_array.push(item)
  })

  A1pack_array[viewlink_cs_pos.A1] = viewlink_checksum(A1pack_array);
  return A1pack_array;
}
function pack_C1(C1CMD) {
  var C1_data_array = C1_to_array(C1CMD);//9
  var C1pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
  C1pack_array[3] = viewlink_cmdLEN.C1;
  C1pack_array[4] = viewlink_cmdID.C1;
  C1_data_array.forEach(function (item) {
    C1pack_array.push(item)
  })
  C1pack_array[viewlink_cs_pos.C1] = viewlink_checksum(C1pack_array);
  return C1pack_array;
}
function pack_E1(E1_CMD) {
  var E1_data_array = E1_to_array(E1_CMD);//9
  var E1pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
  E1pack_array[3] = viewlink_cmdLEN.E1;
  E1pack_array[4] = viewlink_cmdID.E1;
  E1_data_array.forEach(function (item) {
    E1pack_array.push(item)
  })
  E1pack_array[viewlink_cs_pos.E1] = viewlink_checksum(E1pack_array);
  return E1pack_array;

}
function pack_S1(S1_CMD) {
  var S1_data_array = S1_to_array(S1_CMD);//9
  var S1pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
  S1pack_array[3] = viewlink_cmdLEN.S1;
  S1pack_array[4] = viewlink_cmdID.S1;
  S1_data_array.forEach(function (item) {
    S1pack_array.push(item)
  })
  S1pack_array[viewlink_cs_pos.S1] = viewlink_checksum(S1pack_array);
  return S1pack_array;
}
function pack_A2(A2_CMD) {
  var A2_data_array = A2_to_array(A2_CMD);//9
  var A2pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
  A2pack_array[3] = viewlink_cmdLEN.A2; //12
  A2pack_array[4] = viewlink_cmdID.A2;
  A2_data_array.forEach(function (item) {
    A2pack_array.push(item)
  })
  A2pack_array[viewlink_cs_pos.A2] = viewlink_checksum(A2pack_array);
  return A2pack_array;
}
function pack_C2(C2_CMD) {
  var C2_data_array = C2_to_array(C2_CMD);//9
  var C2pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
  C2pack_array[3] = viewlink_cmdLEN.C2;
  C2pack_array[4] = viewlink_cmdID.C2;
  C2_data_array.forEach(function (item) {
    C2pack_array.push(item)
  })
  C2pack_array[viewlink_cs_pos.C2] = viewlink_checksum(C2pack_array);
  return C2pack_array;
}
function pack_E2(E2_CMD) {
  var E2_data_array = E2_to_array(E2_CMD);//9
  var E2pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
  E2pack_array[3] = viewlink_cmdLEN.E2;
  E2pack_array[4] = viewlink_cmdID.E2;
  E2_data_array.forEach(function (item) {
    E2pack_array.push(item)
  })
  E2pack_array[viewlink_cs_pos.E2] = viewlink_checksum(E2pack_array);
  return E2pack_array;
}
function pack_S2(S2_CMD) {
  var S2_data_array = S1_to_array(S2_CMD);//9
  var S2pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
  S2pack_array[3] = viewlink_cmdLEN.S2;
  S2pack_array[4] = viewlink_cmdID.S2;
  S2_data_array.forEach(function (item) {
    S2pack_array.push(item)
  })
  S2pack_array[viewlink_cs_pos.S2] = viewlink_checksum(S2pack_array);
  return S2pack_array;
}
function pack_FW(data, len) {
  var FW_data_array = data;//9
  var FWpack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];

  var viewlink_cmdLEN_FW = len + 3;
  var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
  var viewlink_cs_pos_FW = viewlinkFrameLen_FW - 1;

  FWpack_array[3] = viewlink_cmdLEN_FW;
  FWpack_array[4] = viewlink_cmdID.FW;
  FW_data_array.forEach(function (item) {
    FWpack_array.push(item)
  })
  FWpack_array[viewlink_cs_pos_FW] = viewlink_checksum(FWpack_array);
  return FWpack_array;
}
//////////////A1C1E1 ///////////////
function pack_A1C1E1(A1CMD, C1CMD, E1CMD) {
  var A1_array = A1_to_array(A1CMD);//9
  var C1_array = C1_to_array(C1CMD);//2
  var E1_array = E1_to_array(E1CMD);//3
  //var temp_array1 = a1_array.concat(c1_array, e1_array);

  var A1C1E1_array = [0x55, 0xaa, 0xdc, 0X11, 0X30];
  A1_array.forEach(function (item) {
    A1C1E1_array.push(item)
  })
  C1_array.forEach(function (item) {
    A1C1E1_array.push(item)
  })
  E1_array.forEach(function (item) {
    A1C1E1_array.push(item)
  })
  A1C1E1_array[19] = viewlink_checksum(A1C1E1_array);
  return A1C1E1_array;
}

var T1_1_012_LRFsource = {
  noObject: 0,
  LRFvalue: 1,
  altitudevalue: 2,
  rfvalue: 3
}
var T1_1_34_signal = {
  nosignal: 0,
  timelock: 1,
  lock2D: 2,
  lock3D: 3
}
var T1_1_567_GPS_H_signal = {
  data: 1
}

var T1_2_012_GPS_V_signal = {
  data: 1
}
var T1_2_3_S2 = {
  no: 0,
  yes: 1
}
var T1_2_4567_N = {
  gyrooffsetadj: 0
}


var T1_CMD = {
  B1_bit012: T1_1_012_LRFsource.LRFvalue,
  B1_bit34: T1_1_34_signal.nosignal,
  B1_bit567: T1_1_567_GPS_H_signal.data,
  B2_bit012: T1_2_012_GPS_V_signal.data,
  B2_bit3: T1_2_3_S2.no,
  B2_bit4567: T1_2_4567_N.gyrooffsetadj,
  UAV_latitude: 0x00000000,//1e7
  UAV_longitude: 0x00000000,
  UAV_altitude: 0x0000,
  Target_latitude: 0x00000000,
  Target_longitude: 0x00000000,
  Target_altitude: 0x0000
}


var T2_2_year = {
  year: 2020
}
var T2_2_month = {
  month: 12
}
var T2_day = {
  day: 12
}
var T2_time = {
  time: 0x00000000
}
var T2_CMD = {
  byte1res: 0X00,
  year: T2_2_year.year,
  month: T2_2_month.month,
  day: T2_day.day,
  time: T2_time.time,
  yawGPS: 0x0000,
  UAVyaw: 0x0000,
  UAVpitch: 0x0000,
  UAVroll: 0x0000,
  res1516: 0x0000,
  res1718: 0x0000
}
var T1F1B1D1_CMD = {
  T1data: T1_CMD,

}
var F1_tracksensor = { //跟踪传感器
  eo1: 0,
  ir: 1,
  eo2: 2
}
var F1_trackstatus = { //跟踪状态
  stop: 0,
  search: 1,
  tracking: 2,
  lost: 3
}
var F1_CMD = {
  tracksource: F1_tracksensor.eo1,
  tracksource: F1_tracksensor.ir,
  tracksource: F1_tracksensor.eo2,
  trackstatus: F1_trackstatus.stop,
  trackstatus: F1_trackstatus.search,
  trackstatus: F1_trackstatus.tracking,
  trackstatus: F1_trackstatus.lost
}
var B1_servo_status = {
  motorsw: 0x00,
  manualcontrol: 0x01,
  followyaw: 0x03,
  homeposition: 0x04,
  trackmode: 0x06,
  turntorelativeangle: 0x09,
  lockyaw: 0x0a,
  turntoframeangle: 0x0b,
  RCcontrol: 0x0d,
  fingerto: 0x0e,
  error: 0x0f
}
var B1_CMD = {
  GIMrollangle_8_11: 0,
  servestatus: B1_servo_status.manualcontrol,
  GIMrollangle_0_7: 0x00,
  GIMrollangle: 0,
  GIMyawangle: 0,
  GIMpitchangle: 0
}
var D1_CMD = {
  SENSOR: 0, //bit0-2
  IR_DZOOM: 0,//bit3-6
  IR_WHITE_BLACK: 0,//bit7 
  res: 0x00,
  recstatus: 0,// bit0-1
  ir_gray_color: 0,//bit2-5
  eo_dzoom: 0,//bit6-9
  res: 0,//bit10-15
  lrf_value: 0,//byte 5-6
  v_fov: 0,
  h_fov: 0,
  eo_zoom: 0
}
var modeltype = 0;
//数据分包发送函数
function package_send(buff, len) {
  var i = 0;
  var j = 0;
  var count = 0;
  // var alen = len;
  var hexbuf = [];
  var sendbuf = [];


  if (len % 48 == 0) {
    count = parseInt(len / 48, 10);
  }
  else {
    count = parseInt(len / 48, 10) + 1;
  }

  for (i = 0; i < count; i++) {
    if ((count - i) == 1) {
      for (j = 0; j < len - i * 48; j++) {
        sendbuf[j] = buff[i * 48 + j];
        hexbuf[j] = Number(buff[i * 48 + j]).toString(16);
      }
      return sendbuf
    }
    else {

      for (j = 0; j < 48; j++) {
        sendbuf[j] = buff[i * 48 + j];
        hexbuf[j] = Number(buff[i * 48 + j]).toString(16);
      }
      return sendbuf
    }
  }

}
function msg_shake_hand(rdata) {
  var devicetype = rdata[5] & 0x0f;//55 AA DC 04 00 07 XX 
  var shakehandack = new Array(0x55, 0xaa, 0xdc, 0x04, 0x00, 0x00, 0x00); // GET SHAKE ,FEED BEATHEAT
  shakehandack[4] = 0x10 + devicetype;
  shakehandack[viewlink_cs_pos.SHAKE_HAND] = viewlink_checksum(shakehandack);
  var buff = new Array(0xA5, 0x4f, 0x00);
  buff[2] = mmcCmdLen.SHAKE_HAND;

  shakehandack.forEach(function (item) {
    buff.push(item)
  })
  buff[mmc_cs_pos.SHAKE_HAND] = cal_crc_table(buff);
  package_send(buff, mmcFrameLen.SHAKE_HAND);
  return devicetype;
}

function msg_beatheat(rdata) {

  var devicetype = rdata[4] & 0x0f;
  var buff = new Array(0xA5, 0x4f, 0x00);
  buff[2] = mmcCmdLen.HEART_BEAT;

  rdata.forEach(function (item) {
    buff.push(item)
  })
  buff[mmc_cs_pos.HEART_BEAT] = cal_crc_table(buff);
  package_send(buff, mmcFrameLen.HEART_BEAT);
  return devicetype;
}
function msg_T1_parse(rdata) { //品灵云台F1包解包函数
  T1_CMD.UAV_latitude = ((rdata[2] << 24) | (rdata[3] << 16) | (rdata[4] << 8) | rdata[5]) / 1e7;
  T1_CMD.UAV_longitude = ((rdata[6] << 24) | (rdata[7] << 16) | (rdata[8] << 8) | rdata[9]) / 1e7;
  T1_CMD.UAV_altitude = (rdata[10] << 8) | rdata[11];
  T1_CMD.Target_latitude = ((rdata[12] << 24) | (rdata[13] << 16) | (rdata[14] << 8) | rdata[15]) / 1E7;
  T1_CMD.Target_longitude = ((rdata[16] << 24) | (rdata[17] << 16) | (rdata[18] << 8) | rdata[19]) / 1E7;
  T1_CMD.Target_altitude = (rdata[20] << 8) | rdata[21];

  var int16data = new Int16Array(1);
  int16data[0] = rdata[20] << 8 | rdata[21];
  T1_CMD.Target_altitude = int16data[0];

  set_target_gps(T1_CMD.Target_longitude, T1_CMD.Target_latitude, T1_CMD.Target_altitude);
}
function msg_F1_parse(rdata) { //品灵云台f1包解包函数
  F1_CMD.tracksource = rdata & 0x07;
  F1_CMD.trackstatus = (rdata & 0x18) >> 3;
  set_tracker_status(F1_CMD.trackstatus);
}
function msg_B1_parse(rdata) { //品灵云台B1包解包函数
  B1_CMD.GIMrollangle_8_11 = rdata[0] & 0x0F;
  B1_CMD.servestatus = rdata[0] >> 4;
  B1_CMD.GIMrollangle_0_7 = rdata[1];
  B1_CMD.GIMrollangle = (B1_CMD.GIMrollangle_8_11 << 8 | B1_CMD.GIMrollangle_0_7) * 180 / 4095;
  B1_CMD.GIMrollangle = B1_CMD.GIMrollangle - 90;
  B1_CMD.GIMyawangle = (rdata[2] << 8 | rdata[3]) * 360 / 65536;
  B1_CMD.GIMpitchangle = (rdata[4] << 8 | rdata[5]) * 360 / 65536;
  B1_CMD.GIMyawangle = (B1_CMD.GIMyawangle + 540) % 360 - 180;
  B1_CMD.GIMpitchangle = (B1_CMD.GIMpitchangle + 540) % 360 - 180;

  set_euler_angle(B1_CMD.GIMpitchangle, B1_CMD.GIMrollangle, B1_CMD.GIMyawangle);
}
function msg_D1_parse(rdata) { //品灵云台d1包解包函数
  D1_CMD.SENSOR = rdata[0] & 0x07;
  D1_CMD.IR_DZOOM = (rdata[0] & 0x78) >> 3;
  D1_CMD.IR_WHITE_BLACK = rdata[0] >> 7;
  D1_CMD.recstatus = rdata[3] & 0x03;
  D1_CMD.ir_gray_color = (rdata[3] & 0x3c) >> 2;
  D1_CMD.eo_dzoom = ((rdata[3] & 0xc0) >> 6) | (rdata[2] & 0x03) << 2;
  D1_CMD.lrf_value = rdata[4] << 8 | rdata[5];
  D1_CMD.v_fov = (rdata[6] << 8 | rdata[7]) * 0.01;
  D1_CMD.h_fov = (rdata[8] << 8 | rdata[9]) * 0.01;
  D1_CMD.eo_zoom = (rdata[10] << 8 | rdata[11]) * 0.1;
  var mode = D1_CMD.recstatus;
  set_record_mode(mode);
  set_zoom_times(D1_CMD.eo_zoom);
  set_LRF_distance(D1_CMD.lrf_value / 10);
}
function msg_TIF1B1D1_parse(rdata) {
  var msgdata = new Uint8Array(rdata);
  var T1buf = new Uint8Array(22);
  var F1buf = new Uint8Array(1);
  var B1buf = new Uint8Array(6);
  var D1buf = new Uint8Array(12);
  var i = 0;

  for (i = 0; i < 22; i++) {
    T1buf[i] = msgdata[i + 5];
  }
  for (i = 0; i < 1; i++) {
    F1buf[i] = msgdata[i + 27];
  }
  for (i = 0; i < 6; i++) {
    B1buf[i] = msgdata[i + 28];
  }
  for (i = 0; i < 12; i++) {
    D1buf[i] = msgdata[i + 34];
  }
  msg_T1_parse(T1buf);
  msg_F1_parse(F1buf);
  msg_B1_parse(B1buf);
  msg_D1_parse(D1buf);
  //   console.log('msg_T1_parse', T1buf);
  //   console.log('msg_F1_parse', F1buf);
  //   console.log('msg_B1_parse', B1buf);
  //   console.log('msg_D1_parse', D1buf);
}
//////////////////////////////////////////////////////////////////////
// 防抖
function debounce(func, wait) {
  var timeout;
  return function () {
    var context = this;
    var args = arguments;

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(function () {
      func.apply(context, args)
    }, wait);
  }
}
//crc校验字节生成函数
function cal_crc_table(array) {
  var crc = 0;
  var i = 1;
  var len = array[2];
  while (len--) {
    crc = crc_table[crc ^ array[i]];
    i++;
  }
  return crc;
}


export const MMC_Gimbal_P30 = {
  gimbal_yaw_ctrl(speed) {
    var buff = new Array(0xA5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.A1;

    A1_CMD_RC.server_status = A1_SERVO_STATUS.rcmode;

    A1_CMD_RC.param1 = 0;
    A1_CMD_RC.param2 = speed * 300 + 1500;

    var buff_arr = pack_A1(A1_CMD_RC);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos.A1] = cal_crc_table(buff);
    return package_send(buff, buff[2] + 2);

  },
  gimbal_pitch_ctrl(speed) {// speed 1 -1  0
    var buff = new Array(0xA5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.A1;
    A1_CMD_RC.server_status = A1_SERVO_STATUS.rcmode;
    A1_CMD_RC.param3 = 0;
    A1_CMD_RC.param4 = speed * 300 + 1500;
    var buff_arr = pack_A1(A1_CMD_RC);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos.A1] = cal_crc_table(buff);
    return package_send(buff, mmcFrameLen.A1);
  },
  change_pitch_angle(angle) {
    var buff = new Array(0xA5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.A1;
    A1_CMD_ANGLE.server_status = A1_SERVO_STATUS.turntoframeangle;
    var pitchangle = 0;
    if (angle >= 0) {
      pitchangle = angle;
      A1_CMD_ANGLE.param2 = pitchangle * 65536 / 360;
    }
    else {
      pitchangle = angle;
      A1_CMD_ANGLE.param2 = pitchangle * 65536 / 360 + 65536;
    }
    var buff_arr = pack_A1(A1_CMD_ANGLE);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos.A1] = cal_crc_table(buff);
    return package_send(buff, mmcFrameLen.A1);

  },
  change_yaw_angle(angle) {
    var buff = new Array(0xA5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.A1;
    A1_CMD_ANGLE.server_status = A1_SERVO_STATUS.turntoframeangle;
    var yawangle = 0;
    if (angle >= 0) {
      yawangle = angle;
      A1_CMD_ANGLE.param1 = yawangle * 65536 / 360;
    }
    else {
      yawangle = angle;
      A1_CMD_ANGLE.param1 = yawangle * 65536 / 360 + 65536;
    }
    var buff_arr = pack_A1(A1_CMD_ANGLE);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos.A1] = cal_crc_table(buff);
    return package_send(buff, mmcFrameLen.A1);

  },
  gimbal_mode_ctrl(val){
    // 0 锁定 1 跟随 2 回中
    const bufferList = {
      0: [165, 8, 3, 0, 127],
      1: [165, 8, 3, 1, 78],
      2: [165, 8, 3, 2, 29],
    };
    const buffer = bufferList[val];
    return buffer
  }
}


